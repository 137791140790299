export interface AnnotationAgreementState {
    metricLevel: MetricLevelType;
}

export function AnnotationAgreementStateFactory(data?: Partial<AnnotationAgreementState>): AnnotationAgreementState {
    return {
        metricLevel: 'area',
        ...data,
    };
}

export interface IAnnotationMetricsResponse {
    data: AnnotationMetricsAvgData[];
    comparison: Array<[string, string]>;
    studyIdList: string[];
}

export interface AnnotationMetricsAvgData {
    label: string;
    study_id?: string;
    image_id?: string;
    image_index?: number;
    series_id?: string;
    comparison: Dictionary<IComparison>;
    mean_area?: number;
    mean_volume?: number;
}

export interface AnnotationMetricsAvgRequestPayload {
    annotation_id: string;
    avg_type: AvgType;
    comparison_type: ComparisonType;
    study_id?: string;
    user_id?: string;
    level?: MetricLevelType;
}

export interface IccMetricsRequestPayload {
    annotation_id: string;
    study_id?: string;
}

export interface IccMetricsItem {
    label: string;
    ICC1_1: number;
    ICC2_1: number;
    ICC3_1: number;
    ICC1_k: number;
    ICC2_k: number;
    ICC3_k: number;
}

export type IccMetricKey = keyof Omit<IccMetricsItem, 'label'>;

export interface VolumeListRequestPayload {
    annotation_id: string;
    comparison_type: ComparisonType;
}

export interface VolumeListResponse {
    data: VolumeListData[];
    studyIdList: string[];
    userList: { email: string; displayName: string; _id: string }[];
    projectList: { annotation_id: string; annotation_name: string }[];
}

export interface VolumeListData {
    label: string;
    study_id: string;
    series_id: string;
    volume_value: Dictionary<number>;
}

export interface IComparison {
    match: string;
    dice: number;
    mean_area?: number;
}

export type ComparisonType = 'project' | 'user';
export type AvgType = 'study' | 'label' | 'all';

export type MetricLevelType = 'volume' | 'area';
